export default (i18n) => {
  return [
    {
      name: 'Adh1Civilite',
      value: '',
      required: true,
      field: {
        id: 'civility',
        label: i18n.t('forms.entries.civility.label'),
        type: 'select',
        options: i18n.t('forms.entries.civility.options'),
      },
      messages: i18n.t('forms.entries.civility.messages'),
    },
    {
      name: 'Adh1Nom',
      validator: 'text',
      value: '',
      required: true,
      field: {
        id: 'name',
        label: i18n.t('forms.entries.name.label'),
      },
      messages: i18n.t('forms.entries.name.messages'),
    },
    {
      name: 'Adh1Prenom',
      validator: 'text',
      value: '',
      required: true,
      field: {
        id: 'firstname',
        label: i18n.t('forms.entries.firstname.label'),
      },
      messages: i18n.t('forms.entries.firstname.messages'),
    },
    {
      name: 'Adh1Naiss',
      value: '',
      validator: 'age',
      required: true,
      field: {
        id: 'birthday',
        label: i18n.t('forms.entries.birthday.label'),
        placeholder: i18n.t('forms.entries.birthday.placeholder'),
        mask: 'date',
        inputmode: 'numeric',
      },
      messages: i18n.t('forms.entries.birthday.messages'),
    },
    {
      name: 'Adh1CP',
      value: '',
      validator: 'zipcode',
      required: true,
      field: {
        id: 'address',
        label: i18n.t('forms.entries.zipcode.label'),
      },
      messages: i18n.t('forms.entries.zipcode.messages'),
    },
    {
      name: 'Adh1TelMob',
      value: '',
      validator: 'mobile',
      required: true,
      field: {
        id: 'phone',
        label: i18n.t('forms.entries.mobile.label'),
        type: 'tel',
      },
      messages: i18n.t('forms.entries.mobile.messages'),
    },
    {
      name: 'Adh1Mail',
      value: '',
      validator: 'email',
      required: true,
      field: {
        id: 'email',
        label: i18n.t('forms.entries.email.label'),
        type: 'email',
      },
      messages: i18n.t('forms.entries.email.messages'),
    },
  ];
};
