import inputs from './inputs';

export default (data, i18n) => {
  const idaction = {
    name: 'idaction',
    value: data.gamer_idaction,
    field: {
      type: 'hidden',
    },
  };
  const recipient = {
    name: 'recipient',
    value: data?.mailto[0]?.mailto_recipient,
    field: {
      type: 'hidden',
    },
  };
  const type = {
    name: 'type',
    value: data?.mailto[0]?.mailto_type,
    field: {
      type: 'hidden',
    },
  };

  const form = [...inputs(i18n), idaction, recipient, type];
  return {
    form,
  };
};
