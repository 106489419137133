export default (config, i18n) => {
  const { mailto_type: type, isInternational } = config || null;

  const defaultData = {
    value: '',
    required: true,
  };

  const inputs = [
    {
      name: 'civility',
      ...defaultData,
      field: {
        id: 'civility',
        label: i18n.t('forms.entries.civility.label'),
        type: 'select',
        options: [
          {
            value: '',
            label: 'Civilité',
          },
          {
            value: 'M.',
            label: 'Monsieur',
          },
          {
            value: 'Mme',
            label: 'Madame',
          },
          {
            value: 'Mme',
            label: 'Mademoiselle',
          },
        ],
      },
      messages: i18n.t('forms.entries.civility.messages'),
    },
    {
      name: 'name',
      validator: 'text',
      ...defaultData,
      field: {
        id: 'name',
        label: i18n.t('forms.entries.name.label'),
        maxlength: '30',
      },
      messages: i18n.t('forms.entries.name.messages'),
    },
    {
      name: 'firstname',
      validator: 'text',
      ...defaultData,
      field: {
        id: 'firstname',
        label: i18n.t('forms.entries.firstname.label'),
        maxlength: '20',
      },
      messages: i18n.t('forms.entries.firstname.messages'),
    },
    {
      name: 'phone',
      ...defaultData,
      validator: isInternational ? 'internationalPhone' : 'phone',
      field: {
        id: 'phone',
        label: i18n.t('forms.entries.phone.label'),
        type: 'tel',
      },
      messages: i18n.t('forms.entries.phone.messages'),
    },
    {
      name: 'email',
      ...defaultData,
      validator: 'email',
      field: {
        id: 'email',
        label: i18n.t('forms.entries.email.label'),
        type: 'email',
      },
      messages: i18n.t('forms.entries.email.messages'),
    },
  ];

  if (type === 'azzur' || type === 'pipedrive') {
    return inputs;
  }

  // Subject
  if (config.subject && config.subject.length) {
    let name = 'subject';
    if (type.includes('claim-')) name = 'claim';
    else if (type !== 'pre-sale') name = 'contact';

    const optionsObject = i18n.t(`forms.entries.${name}.options`);
    const options = optionsObject
      ? Object.keys(optionsObject)?.map((key) => optionsObject[key])
      : [];

    config.subject.forEach(({ form_subject_label, form_subject_recipient }) => {
      options?.push({
        value: `${form_subject_label}|${form_subject_recipient}`,
        label: form_subject_label,
      });
    });

    inputs.push({
      name: name,
      ...defaultData,
      field: {
        id: name,
        label: i18n.t(`forms.entries.${name}.label`),
        type: 'select',
        options,
      },
      messages: i18n.t(`forms.entries.${name}.messages`),
    });
  }

  // Après-vente & réclamation
  if (type !== 'pre-sale') {
    inputs.push({
      name: 'message',
      validator: 'textarea',
      ...defaultData,
      field: {
        id: 'message',
        label: i18n.t('forms.entries.message.label'),
        type: 'textarea',
        maxlength: '400',
      },
      messages: i18n.t('forms.entries.message.messages'),
    });

    if (type === 'claim-pro-client' || type === 'claim-pro-prospect') {
      // Social reason
      inputs.unshift({
        name: 'socialreason',
        validator: 'text',
        ...defaultData,
        field: {
          id: 'socialreason',
          label: i18n.t('forms.entries.socialreason.label'),
        },
        messages: i18n.t('forms.entries.socialreason.messages'),
      });
    }

    if (
      type !== 'claim-particulier-prospect' &&
      type !== 'claim-pro-prospect'
    ) {
      // Member number
      inputs.unshift({
        name: 'member',
        ...defaultData,
        field: {
          id: 'member',
          label: i18n.t('forms.entries.member.label'),
        },
        messages: i18n.t('forms.entries.member.messages'),
      });
    }
  }

  if (config.mailto_company_field) {
    inputs.unshift({
      name: 'company',
      ...defaultData,
      field: {
        id: 'company',
        label: i18n.t('forms.entries.company.label'),
      },
      messages: i18n.t('forms.entries.company.messages'),
    });
  }

  return inputs;
};
