import inputs from './inputs';
import hiddenInputs from './hiddenInputs';
import customInputs from './customInputs';

export default (data, i18n) => {
  const config = {
    ...data.mailto[0],
    subject: data.mailto_subject,
    isPro: data.is_pro,
    isInternational: data.is_international,
  };
  const customFields = data.body;
  const replaceWithCustomFields = data.override;

  const form = replaceWithCustomFields
    ? [...customInputs(customFields, i18n), ...hiddenInputs(config)]
    : [
        ...inputs(config, i18n),
        ...hiddenInputs(config),
        ...customInputs(customFields, i18n),
      ];

  return {
    form,
  };
};
