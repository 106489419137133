const getInput = (inputs, name) => {
  const baseInput = inputs.find((input) => input.name === name);
  const input = { ...baseInput, ...baseInput?.field };
  input.title = input.label;

  if (!input.validator) {
    delete input.validator;
  } else {
    input.validator = { validate: input.validator };
  }

  if (!input.type) {
    input.type = 'text';
  }

  if (input.validator) {
    if (name === 'effectiveDate') {
      input.validator.validate = 'effectiveDate';
    } else if (name === 'birthDate') {
      input.validator.validate = 'matureAge';
    }
  }

  if (input.options && input.type === 'radio') {
    input.inputs = [];

    Object.keys(input.options).map((optionKey) => {
      input.inputs.push(input.options[optionKey]);
    });

    delete input.options;
  }

  delete input.field;
  return input;
};

export const getSteps = (inputs) => [
  {
    primaryCta: { label: 'Suivant', variant: 'primary' },
    secondaryCta: { label: 'Précédent', variant: 'secondary' },
    intro: {
      title: 'Informations concernant <b>votre prêt immobilier</b>',
    },
    inputs: [
      getInput(inputs, 'euroAmount'),
      getInput(inputs, 'rate'),
      getInput(inputs, 'duration'),
      getInput(inputs, 'effectiveDate'),
      getInput(inputs, 'smokingType'),
    ],
  },
  {
    primaryCta: {
      label: 'Suivant',
      variant: 'primary',
    },
    secondaryCta: {
      label: 'Précédent',
      variant: 'secondary',
    },
    intro: {
      title: 'Vos <b>informations</b>',
    },
    inputs: [
      getInput(inputs, 'civility'),
      getInput(inputs, 'surname'),
      getInput(inputs, 'name'),
      getInput(inputs, 'birthDate'),
    ],
  },
  {
    primaryCta: {
      label: 'Demander un devis',
      variant: 'primary',
    },
    secondaryCta: {
      label: 'Précédent',
      variant: 'secondary',
    },
    intro: {
      title: 'Vos <b>coordonnées</b>',
    },
    isSubmitStep: true,
    description: `En cliquant sur « Demander un devis », j’accepte d’être accompagné par téléphone et/ou email dans le cadre de ma démarche d’assurance.
    Retrouvez plus d’informations sur le traitement de vos données dans notre <a class="desc-link" href="https://assets.april.fr/prismic/documents/juridique/doc-rgpd-april-sante-prevoyance-lettre-informations-donnees-personnelles.pdf?func=proxy" target="'_blank'" rel="noopener">Politique données personnelles</a>.`,
    inputs: [
      getInput(inputs, 'zipCode'),
      getInput(inputs, 'phone'),
      getInput(inputs, 'email'),
    ],
  },
];
