import cmsFields from '../utils/cms-fields';
import inputs from './inputs';
import customInputs from './customInputs';

export default (data, i18n) => {
  const hiddenFields = data.merlin[0];
  const customFields = data.body;
  const replaceWithCustomFields = data.override;

  const finalInputs = replaceWithCustomFields
    ? customInputs(customFields, i18n)
    : [...inputs(i18n), ...customInputs(customFields, i18n)];

  const form = [...finalInputs, ...cmsFields(hiddenFields)];

  return {
    form,
  };
};
