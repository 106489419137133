import inputs from '../mailto/inputs';
import customInputs from '../mailto/customInputs';

const getHiddenField = (name, data) => ({
  name,
  value: data[name],
  field: {
    type: 'hidden',
  },
});

export default (data, i18n) => {
  const type = data.azzur_type || 'rcd';
  const config = {
    ...data.mailto[0],
    isInternational: data.is_international,
    mailto_type: 'azzur',
  };
  const customFields = data.body;
  const replaceWithCustomFields = data.override;
  const hiddenFields = [
    getHiddenField('type', { type }),
    getHiddenField('azzur_id_prov', data),
    getHiddenField('azzur_id_agence', data),
  ];

  switch (type) {
    case 'rcd':
      hiddenFields.push(getHiddenField('azzur_rcd_pm', data));
      break;
    case 'auto':
      hiddenFields.push(getHiddenField('azzur_auto_pm', data));
      break;
    default:
      break;
  }

  const form = replaceWithCustomFields
    ? [...customInputs(customFields, i18n), ...hiddenFields]
    : [
        ...inputs(config, i18n),
        ...customInputs(customFields, i18n),
        ...hiddenFields,
      ];

  return {
    form,
  };
};
