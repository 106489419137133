import getDefaultDate from '../utils/get-default-date';
import getJoinedFields from '../utils/get-joined-fields';

export default (fields, i18n) => {
  const joinedFields = getJoinedFields(fields);

  const customInputs = {
    'date-effet': {
      name: 'DateEffet',
      value: getDefaultDate(),
      validator: 'dateEffet',
      required: true,
      field: {
        id: 'date-effet',
        label: i18n.t('forms.entries.dateeffet.label'),
        placeholder: i18n.t('forms.entries.dateeffet.placeholder'),
        mask: 'date',
        inputmode: 'numeric',
        helper: i18n.t('forms.entries.dateeffet.helper'),
      },
      messages: i18n.t('forms.entries.dateeffet.messages'),
    },
    'montant-emprunt': {
      name: 'MontantPret1',
      value: '',
      validator: 'montantEmprunt',
      required: true,
      field: {
        id: 'emprunt',
        label: i18n.t('forms.entries.montantemprunt.label'),
        inputmode: 'numeric',
        mask: 'emprunt',
        unit: '€',
        helper: i18n.t('forms.entries.montantemprunt.helper'),
      },
      messages: i18n.t('forms.entries.montantemprunt.messages'),
    },
    'taux-pret': {
      name: 'TauxPret1',
      value: '',
      required: true,
      validator: 'tauxPret',
      field: {
        id: 'taux',
        label: i18n.t('forms.entries.tauxpret.label'),
        inputmode: 'decimal',
        type: 'number',
        min: 0,
        max: 20,
        step: '0.01',
        unit: '%',
      },
      messages: i18n.t('forms.entries.tauxpret.messages'),
    },
    'duree-pret': {
      name: 'DureePret1',
      value: '',
      required: true,
      validator: 'dureePret',
      field: {
        id: 'taux',
        label: i18n.t('forms.entries.dureepret.label'),
        inputmode: 'numeric',
        type: 'number',
        min: 1,
        max: 480,
        unit: 'mois',
      },
      messages: i18n.t('forms.entries.dureepret.messages'),
    },
    fumeur: {
      name: 'Fumeur',
      value: '',
      required: true,
      field: {
        id: 'fumeur',
        label: i18n.t('forms.entries.fumeur.label'),
        type: 'radio',
        options: i18n.t('forms.entries.fumeur.options'),
      },
      messages: i18n.t('forms.entries.fumeur.messages'),
    },
  };

  return joinedFields
    .filter(({ champ }) => !!customInputs[champ])
    ?.map(({ champ }) => customInputs[champ]);
};
