import { toPascalCase } from '~/utils/case-transform';

export default (fields) => {
  return Object.entries(fields).map(([key, value]) => {
    return {
      name: toPascalCase(key),
      value: value,
      field: {
        type: 'hidden',
      },
    };
  });
};
