import cmsFields from '../utils/cms-fields';
import inputs from './inputs';
import customInputs from './customInputs';

export default (data, i18n) => {
  const market = {
    name: 'market',
    value: data.marketplace_market ? 'borrower' : 'health',
    field: {
      type: 'hidden',
    },
  };

  const hiddenFields = data.marketplace[0];
  const customFields = data.body;
  const replaceWithCustomFields = data.override;

  const finalInputs = replaceWithCustomFields
    ? customInputs(customFields, i18n)
    : [...inputs(i18n), ...customInputs(customFields, i18n)];

  const form = [...finalInputs, ...cmsFields(hiddenFields), market];

  return {
    form,
    hiddenFields,
  };
};
