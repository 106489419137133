export default () => {
  const date = new Date();
  date.setDate(date.getDate() + 1);
  const day = date.getDate();
  const month = date.getMonth();
  const year = date.getFullYear();

  const formatNumber = (number) => (number >= 10 ? number : `0${number}`);

  return `${formatNumber(day)}/${formatNumber(month + 1)}/${year}`;
};
