import { TYPES, DEFAULT_TYPE } from './adapter';

const getHiddenField = (name, data) => ({
  name,
  value: data[name],
  field: {
    type: 'hidden',
  },
});

const getHiddenFields = (data) => {
  const type = data.mri_type || DEFAULT_TYPE;

  return [
    getHiddenField('mri_type', { mri_type: type }),
    getHiddenField('mri_vecteur', data),
    getHiddenField('mri_marche', data),
    getHiddenField('mri_produit', data),
  ];
};

const listProFields = (i18n, isInternational) => {
  const fields = [
    {
      name: 'civility',
      value: '',
      required: true,
      field: {
        id: 'civility',
        label: i18n.t('mri.entries.civility.label'),
        type: 'select',
        options: [
          {
            value: '',
            label: 'Civilité',
          },
          {
            value: 'M.',
            label: 'Monsieur',
          },
          {
            value: 'Mme',
            label: 'Madame',
          },
        ],
      },
      messages: i18n.t('mri.entries.civility.messages'),
    },
    {
      name: 'firstname',
      validator: 'text',
      value: '',
      required: true,
      field: {
        id: 'firstname',
        label: i18n.t('forms.entries.firstname.label'),
        maxLength: 20,
        showMaxLength: false,
      },
      messages: i18n.t('forms.entries.firstname.messages'),
    },
    {
      name: 'name',
      validator: 'text',
      value: '',
      required: true,
      field: {
        id: 'name',
        label: i18n.t('forms.entries.name.label'),
        maxLength: 30,
        showMaxLength: false,
      },
      messages: i18n.t('forms.entries.name.messages'),
    },
    {
      name: 'nascdate',
      value: '',
      validator: 'dateCreation',
      required: true,
      field: {
        id: 'nascdate',
        label: i18n.t('mri.entries.birthday.label'),
        placeholder: i18n.t('mri.entries.birthday.placeholder'),
        mask: 'date',
        inputmode: 'numeric',
      },
      messages: i18n.t('mri.entries.birthday.messages'),
    },
    {
      name: 'codepostal',
      value: '',
      required: true,
      validator: 'zipcode',
      field: {
        id: 'codepostal',
        label: i18n.t('forms.entries.zipcode.label'),
      },
      messages: i18n.t('forms.entries.zipcode.messages'),
    },
    {
      name: 'phone',
      value: '',
      required: true,
      validator: isInternational ? 'internationalPhone' : 'phone',
      field: {
        id: 'phone',
        label: i18n.t('mri.entries.mobile.label'),
        type: 'tel',
      },
      messages: i18n.t('mri.entries.mobile.messages'),
    },
    {
      name: 'email',
      value: '',
      required: true,
      validator: 'email',
      field: {
        id: 'email',
        label: i18n.t('forms.entries.email.label'),
        type: 'email',
      },
      messages: i18n.t('forms.entries.email.messages'),
    },
  ];
  return fields;
};

const listCollectiveFields = (i18n, isInternational) => {
  const fields = [
    {
      name: 'society',
      validator: 'text',
      value: '',
      required: true,
      field: {
        id: 'society',
        label: i18n.t('mri.entries.society.label'),
      },
      messages: i18n.t('mri.entries.society.messages'),
    },
    {
      name: 'numsiret',
      value: '',
      required: true,
      field: {
        id: 'numsiret',
        label: i18n.t('mri.entries.numsiret.label'),
        maxLength: 14,
        inputmode: 'numeric',
        type: 'number',
        showMaxLength: false,
      },
      messages: i18n.t('mri.entries.numsiret.messages'),
    },
    {
      name: 'effectif',
      value: '',
      required: true,
      field: {
        id: 'effectif',
        label: i18n.t('mri.entries.effectif.label'),
        inputmode: 'numeric',
        type: 'number',
      },
      messages: i18n.t('mri.entries.effectif.messages'),
    },
    {
      name: 'civility',
      value: '',
      required: true,
      field: {
        id: 'civility',
        label: i18n.t('forms.entries.civility.label'),
        type: 'select',
        options: [
          {
            value: '',
            label: 'Civilité',
          },
          {
            value: 'M.',
            label: 'Monsieur',
          },
          {
            value: 'Mme',
            label: 'Madame',
          },
        ],
      },
      messages: i18n.t('forms.entries.civility.messages'),
    },
    {
      name: 'firstname',
      validator: 'text',
      value: '',
      required: true,
      field: {
        id: 'firstname',
        label: i18n.t('forms.entries.firstname.label'),
        maxLength: 20,
        showMaxLength: false,
      },
      messages: i18n.t('forms.entries.firstname.messages'),
    },
    {
      name: 'name',
      validator: 'text',
      value: '',
      required: true,
      field: {
        id: 'name',
        label: i18n.t('forms.entries.name.label'),
        maxLength: 30,
        showMaxLength: false,
      },
      messages: i18n.t('forms.entries.name.messages'),
    },
    {
      name: 'codepostal',
      value: '',
      required: true,
      validator: 'zipcode',
      field: {
        id: 'codepostal',
        label: i18n.t('forms.entries.zipcode.label'),
      },
      messages: i18n.t('forms.entries.zipcode.messages'),
    },
    {
      name: 'ville',
      validator: 'text',
      value: '',
      required: true,
      field: {
        id: 'ville',
        label: i18n.t('forms.entries.city.label'),
      },
      messages: i18n.t('forms.entries.city.messages'),
    },
    {
      name: 'phone',
      value: '',
      required: true,
      validator: isInternational ? 'internationalPhone' : 'phone',
      field: {
        id: 'phone',
        label: i18n.t('mri.entries.mobile.label'),
        type: 'tel',
      },
      messages: i18n.t('mri.entries.mobile.messages'),
    },
    {
      name: 'email',
      value: '',
      required: true,
      validator: 'email',
      field: {
        id: 'email',
        label: i18n.t('forms.entries.email.label'),
        type: 'email',
      },
      messages: i18n.t('forms.entries.email.messages'),
    },
  ];
  return fields;
};

const generateFields = (data, i18n) => {
  const type = data.mri_type || DEFAULT_TYPE;
  const isInternational = data?.mailto?.[0]?.isInternational || false;

  const fields =
    type === TYPES.PRO
      ? listProFields(i18n, isInternational)
      : listCollectiveFields(i18n, isInternational);

  const hiddenFields = getHiddenFields(data);

  const mergedFields = [...fields, ...hiddenFields];

  return [...mergedFields];
};

export default generateFields;
