import getDefaultDate from '../utils/get-default-date';
import getJoinedFields from '../utils/get-joined-fields';

export default (fields, i18n) => {
  const joinedFields = getJoinedFields(fields);

  const baseInput = (name, value, validator, required) => ({
    name,
    value,
    validator,
    required,
  });

  const baseField = (id, label) => ({
    id,
    label: i18n.t(`forms.entries.${label}.label`),
  });

  const customInputs = {
    'date-effet': {
      ...baseInput('effectiveDate', getDefaultDate(), 'dateEffet', true),
      field: {
        ...baseField('date-effet', 'dateeffet'),
        placeholder: i18n.t('forms.entries.dateeffet.placeholder'),
        mask: 'date',
        inputmode: 'numeric',
        helper: i18n.t('forms.entries.dateeffet.helper'),
      },
      messages: i18n.t('forms.entries.dateeffet.messages'),
    },
    'montant-emprunt': {
      ...baseInput('euroAmount', '', 'montantEmprunt', true),
      field: {
        ...baseField('emprunt', 'montantemprunt'),
        inputmode: 'numeric',
        mask: 'emprunt',
        unit: '€',
        helper: i18n.t('forms.entries.montantemprunt.helper'),
      },
      messages: i18n.t('forms.entries.montantemprunt.messages'),
    },
    'taux-pret': {
      ...baseInput('rate', '', 'tauxPret', true),
      field: {
        ...baseField('taux', 'tauxpret'),
        type: 'number',
        inputmode: 'decimal',
        min: 0,
        max: 20,
        step: '0.01',
        unit: '%',
      },
      messages: i18n.t('forms.entries.tauxpret.messages'),
    },
    'duree-pret': {
      ...baseInput('duration', '', 'dureePret', true),
      field: {
        ...baseField('duree', 'dureepret'),
        type: 'number',
        inputmode: 'numeric',
        min: 1,
        max: 480,
        unit: 'mois',
      },
      messages: i18n.t('forms.entries.dureepret.messages'),
    },
    fumeur: {
      ...baseInput('smokingType', 'NOT_SMOKING', null, true),
      field: {
        ...baseField('fumeur', 'fumeur'),
        type: 'radio',
        options: i18n.t('forms.entries.fumeur.options-extended'),
      },
      messages: i18n.t('forms.entries.fumeur.messages'),
    },
  };

  return joinedFields
    .filter(({ champ }) => !!customInputs[champ])
    ?.map(({ champ }) => customInputs[champ]);
};
