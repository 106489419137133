import { RichText } from 'prismic-dom';

export default (config) => {
  const type = config.mailto_type;
  const hiddenField = {
    type: 'hidden',
  };

  const inputs = [
    {
      name: 'type',
      value: type,
      field: hiddenField,
    },
  ];

  const market = config.mailto_market?.data?.title;
  const market_custom = config.mailto_market_custom;
  if (market) {
    inputs.push({
      name: 'market',
      value: market,
      field: hiddenField,
    });
  } else if (market_custom) {
    inputs.push({
      name: 'market',
      value: market_custom,
      field: hiddenField,
    });
  }

  const product = config.mailto_product?.data?.title;
  const product_custom = config.mailto_product_custom;
  if (product) {
    inputs.push({
      name: 'product',
      value: RichText.asText(product),
      field: hiddenField,
    });
  } else if (product_custom) {
    inputs.push({
      name: 'product',
      value: product_custom,
      field: hiddenField,
    });
  }

  if (config.mailto_recipient) {
    inputs.push({
      name: 'recipient',
      value: config.mailto_recipient,
      field: hiddenField,
    });
  }

  return inputs;
};
