import merlin from './merlin';
import mailto from './mailto';
import gamer from './gamer';
import azzur from './azzur';
import marketplace from './marketplace';
import pipedrive from './pipedrive';
import mri from './mri';

export default (data, i18n, formType) => {
  const type = data.form_api.toLowerCase();

  if (type === 'merlin') {
    return merlin(data, i18n);
  }

  if (type === 'mailto') {
    return mailto(data, i18n);
  }

  if (type === 'gamer') {
    return gamer(data, i18n);
  }

  if (type === 'marketplace') {
    return marketplace(data, i18n);
  }

  if (type === 'azzur') {
    return azzur(data, i18n);
  }

  if (type === 'pipedrive') {
    return pipedrive(data, i18n, formType);
  }

  if (type === 'mri') {
    return mri(data, i18n);
  }

  if (process.env.NODE_ENV !== 'production') {
    console.warn(`No API found matching ${type}.`);
  }
  return {};
};
