export default (fields, i18n) => {
  const getNameInput = (name, type) => ({
    name,
    validator: 'text',
    value: '',
    required: true,
    field: {
      id: name,
      label: i18n.t(`forms.entries.${type}.label`),
      minLength: '1',
    },
    messages: i18n.t(`forms.entries.${type}.messages`),
  });

  const customInputs = {
    adresse: {
      name: 'address',
      validator: 'text',
      value: '',
      required: true,
      field: {
        id: 'adresse',
        label: i18n.t('forms.entries.addressFull.label'),
      },
      messages: i18n.t('forms.entries.addressFull.messages'),
    },
    'code-postal': {
      name: 'zipcode',
      value: '',
      required: true,
      validator: 'zipcode',
      field: {
        id: 'zipcode',
        label: i18n.t('forms.entries.zipcode.label'),
      },
      messages: i18n.t('forms.entries.zipcode.messages'),
    },
    ville: {
      name: 'city',
      validator: 'text',
      value: '',
      required: true,
      field: {
        id: 'city',
        label: i18n.t('forms.entries.city.label'),
      },
      messages: i18n.t('forms.entries.city.messages'),
    },
    pays: {
      name: 'country',
      validator: 'text',
      value: '',
      required: true,
      field: {
        id: 'country',
        label: i18n.t('forms.entries.country.label'),
      },
      messages: i18n.t('forms.entries.country.messages'),
    },
    'num-orias': {
      name: 'numeroOrias',
      validator: 'text',
      value: '',
      required: true,
      field: {
        id: 'orias',
        label: i18n.t('forms.entries.orias.label'),
      },
      messages: i18n.t('forms.entries.orias.messages'),
    },
    'num-siren': {
      name: 'numeroSiren',
      value: '',
      required: true,
      validator: 'siren',
      field: {
        id: 'siren',
        label: i18n.t('forms.entries.siren.label'),
        inputmode: 'numeric',
      },
      messages: i18n.t('forms.entries.siren.messages'),
    },
    message: {
      name: 'message',
      value: '',
      validator: 'textarea',
      field: {
        id: 'message',
        label: i18n.t('forms.entries.messagecourtage.label'),
        type: 'textarea',
      },
    },
    offres: {
      name: 'offres',
      value: [],
      validator: 'multiCheckbox',
      field: {
        id: 'offres',
        type: 'checkbox',
        label: 'Vous souhaitez proposer des offres :',
        options: [
          {
            value: 'assurance-pret',
            label: 'Assurance de prêt',
          },
          {
            value: 'sante-prevoyance-part',
            label: 'Santé et Prévoyance des particuliers',
          },
          {
            value: 'sante-prevoyance-pro',
            label: 'Santé et Prévoyance des professionnels',
          },
          {
            value: 'sante-prevoyance-entreprise',
            label: 'Santé et Prévoyance des entreprises',
          },
          {
            value: 'auto-habitation-multirisque-commercants',
            label:
              'Auto, Habitation, Multirisque commerçants, Pro cyclo livraison, Auto Pro',
          },
          {
            value: 'rc-decenenale-dommage-ouvrage',
            label: 'RC Décennale, Dommage ouvrage',
          },
          {
            value: 'expat-impat-voyage',
            label: 'Expatriés, Impatriés, Voyages',
          },
          {
            value: 'moto-scooter-quad-trottinette-camping-car',
            label: 'Moto, Scooter, Quad, Trottinette, Camping-car',
          },
          {
            value: 'loyer-impayes-multirisques-immeuble',
            label: 'Loyers impayés, Multirisque immeuble',
          },
          {
            value: 'bateau-jet-ski',
            label: 'Bateau, Jet-ski',
          },
        ],
      },
      messages: {
        invalid: 'Veuillez sélectionner au moins une offre',
      },
    },
    'nom-parrain': getNameInput('nomParrain', 'name'),
    'prenom-parrain': getNameInput('prenomParrain', 'firstname'),
    'email-parrain': {
      name: 'emailParrain',
      value: '',
      required: true,
      validator: 'email',
      field: {
        id: 'emailParrain',
        label: i18n.t('forms.entries.email.label'),
        type: 'email',
      },
      messages: i18n.t('forms.entries.email.messages'),
    },
    'num-adherant-parrain': {
      name: 'numAdherentParrain',
      value: '',
      required: true,
      validator: 'member',
      field: {
        id: 'numAdherentParrain',
        label: i18n.t('forms.entries.member.label'),
        helper: i18n.t('forms.entries.member.helper'),
      },
      messages: i18n.t('forms.entries.member.messages'),
    },
    'nom-filleul': getNameInput('nomFilleul', 'name'),
    'prenom-filleul': getNameInput('prenomFilleul', 'firstname'),
    'date-filleul': {
      name: 'dateFilleul',
      value: '',
      validator: 'age',
      required: true,
      field: {
        id: 'dateFilleul',
        label: i18n.t('forms.entries.birthday.label'),
        placeholder: i18n.t('forms.entries.birthday.placeholder'),
        mask: 'date',
        inputmode: 'numeric',
      },
      messages: i18n.t('forms.entries.birthday.messages'),
    },
    'code-postal-filleul': {
      name: 'codePostalFilleul',
      value: '',
      validator: 'zipcode',
      required: true,
      field: {
        id: 'codePostalFilleul',
        label: i18n.t('forms.entries.zipcode.label'),
      },
      messages: i18n.t('forms.entries.zipcode.messages'),
    },
    'tel-filleul': {
      name: 'telFilleul',
      value: '',
      validator: 'mobile',
      required: true,
      field: {
        id: 'telFilleul',
        label: i18n.t('forms.entries.mobile.label'),
        type: 'tel',
      },
      messages: i18n.t('forms.entries.mobile.messages'),
    },
    'email-filleul': {
      name: 'emailFilleul',
      value: '',
      validator: 'email',
      required: true,
      field: {
        id: 'emailFilleul',
        label: i18n.t('forms.entries.email.label'),
        type: 'email',
      },
      messages: i18n.t('forms.entries.email.messages'),
    },
    'ville-filleul': {
      name: 'villeFilleul',
      validator: 'text',
      value: '',
      required: true,
      field: {
        id: 'villeFilleul',
        label: i18n.t('forms.entries.city.label'),
      },
      messages: i18n.t('forms.entries.city.messages'),
    },
    'contrat-filleul': {
      name: 'contratFilleul',
      value: [],
      required: true,
      validator: 'multiCheckbox',
      field: {
        id: 'contratFilleul',
        label: i18n.t('forms.entries.contratfilleul.label'),
        type: 'checkbox',
        options: [
          {
            value: 'Santé',
            label: 'Santé',
          },
          {
            value: 'Emprunteur',
            label: 'Emprunteur',
          },
        ],
      },
      messages: i18n.t('forms.entries.contratfilleul.messages'),
    },
    'rappel-filleul': {
      name: 'rappelFilleul',
      value: '',
      required: true,
      field: {
        id: 'rappelFilleul',
        type: 'checkbox',
        options: [
          {
            value: 'true',
            label: i18n.t('forms.entries.rappelfilleul.label'),
          },
        ],
      },
      messages: i18n.t('forms.entries.rappelfilleul.messages'),
    },
    precisions: {
      name: 'precisions',
      value: '',
      validator: 'textarea',
      field: {
        id: 'precisions',
        label: i18n.t('forms.entries.precisions.label'),
        type: 'textarea',
      },
    },
    civ: {
      name: 'civ',
      value: '',
      required: false,
      field: {
        id: 'civ',
        label: i18n.t('forms.entries.civility.label'),
        type: 'select',
        options: i18n.t('forms.entries.civility.options'),
      },
      messages: i18n.t('forms.entries.civility.messages'),
    },
    nom: getNameInput('nom', 'name'),
    prenom: getNameInput('prenom', 'firstname'),
    'activites-exercees': {
      name: 'activitesExercees',
      validator: 'text',
      value: '',
      required: true,
      field: {
        id: 'activitesExercees',
        label: i18n.t('forms.entries.activitesexercees.label'),
        minLength: '1',
        helper: i18n.t('forms.entries.activitesexercees.helper'),
      },
      messages: i18n.t('forms.entries.activitesexercees.messages'),
    },
    'chiffre-affaires': {
      name: 'chiffreAffaires',
      value: '',
      required: true,
      field: {
        id: 'chiffreAffaires',
        label: i18n.t('forms.entries.chiffreaffaires.label'),
        inputmode: 'numeric',
        mask: 'emprunt',
        unit: '€',
        helper: i18n.t('forms.entries.chiffreaffaires.helper'),
      },
      messages: i18n.t('forms.entries.chiffreaffaires.messages'),
    },
    'date-creation-entreprise': {
      name: 'dateCreationEntreprise',
      value: '',
      validator: 'dateCreation',
      required: true,
      field: {
        id: 'dateCreationEntreprise',
        label: i18n.t('forms.entries.datecreationentreprise.label'),
        placeholder: i18n.t('forms.entries.datecreationentreprise.placeholder'),
        mask: 'date',
        inputmode: 'numeric',
      },
      messages: i18n.t('forms.entries.datecreationentreprise.messages'),
    },
    'e-mail': {
      name: 'email',
      value: '',
      required: true,
      validator: 'email',
      field: {
        id: 'email',
        label: i18n.t('forms.entries.email.label'),
        type: 'email',
      },
      messages: i18n.t('forms.entries.email.messages'),
    },
    'tel-mobile': {
      name: 'telMobile',
      value: '',
      validator: 'mobile',
      required: true,
      field: {
        id: 'telMobile',
        label: i18n.t('forms.entries.mobile.label'),
        type: 'tel',
      },
      messages: i18n.t('forms.entries.mobile.messages'),
    },
    rcd_deja_assure: {
      name: 'rcd_deja_assure',
      value: '',
      required: true,
      field: {
        id: 'rcd_deja_assure',
        type: 'radio',
        label: i18n.t('forms.entries.rcd_deja_assure.label'),
        options: [
          {
            value: '1',
            label: 'Oui',
          },
          {
            value: '2',
            label: 'Non',
          },
        ],
      },
      messages: i18n.t('forms.entries.rcd_deja_assure.messages'),
    },
  };

  const getGroup = (title, name, groupInputs) => ({
    name,
    field: {
      type: 'fieldset',
      label: title,
      inputs: groupInputs.map(({ champ }) => customInputs[champ]),
    },
  });

  const joinedFields = fields.reduce((array, { items, primary }) => {
    if (primary && !!primary.group_title && items) {
      array.push({
        group: primary.group_title,
        groupInputs: items,
      });
      return array;
    }
    return array.concat(items);
  }, []);

  return joinedFields
    .filter(
      ({ champ, group, groupInputs }) =>
        !!customInputs[champ] || (!!group && !!groupInputs)
    )
    ?.map(({ champ, group, groupInputs }, index) => {
      if (group) {
        return getGroup(group, `group-${index}`, groupInputs);
      }
      return customInputs[champ];
    });
};
